import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';

export default function AvisoLegal() {
	return (
		<LayoutES>
			<Seo title='Transparencia' />
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					maxWidth: '800px',
					padding: '60px 0',
				}}
			>
				<h1>Portal de Transparencia</h1>
				<p>Entidad: KUFLOW, S.L. (en adelante KUFLOW)</p>
				<p>CIF: B02951978</p>
				<p>Actualizado a fecha 18 de marzo de 2024</p>
				<p>&nbsp;</p>
				<h2>1. Normativa aplicable a la entidad</h2>
				<ul>
					<li>Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas.	</li>
					<li>Ley 40/2015, de 1 de octubre, de Régimen Jurídico del Sector Público.	</li>
					<li>Real Decreto 311/2022, de 3 de mayo, por el que se regula el Esquema Nacional de Seguridad.	</li>
					<li>Resolución de 13 de octubre de 2016, de la Secretaría de Estado de Administraciones Públicas, por la que se aprueba la Instrucción Técnica de Seguridad de conformidad con el Esquema Nacional de Seguridad.	</li>
					<li>Reglamento (UE) n.º 910/2014 del Parlamento Europeo y del Consejo, de 23 de julio de 2014, relativo a la identificación electrónica y los servicios de confianza para las transacciones electrónicas en el mercado interior y por el que se deroga la Directiva 1999/93/CE	</li>
					<li>Real Decreto 4/2010, de 8 de enero, por el que se regula el Esquema Nacional de Interoperabilidad en el ámbito de la Administración Electrónica.	</li>
					<li>Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos, RGPD). 	</li>
					<li>Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD/GDD).	</li>
					<li>Artículos 23 y 24 de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal. 	</li>
					<li>Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico.	</li>
					<li>Ley 37/2007, de 16 de noviembre, sobre reutilización de la información del sector público	</li>
					<li>Ley 6/2020, de 11 de noviembre, reguladora de determinados aspectos de los servicios electrónicos de confianza	</li>
					<li>Real Decreto 1553/2005, de 23 de diciembre, por el que se regula la expedición del documento nacional de identidad y sus certificados de firma electrónica.	</li>
					<li>Reglamento (UE) nº 910/2014 (eIDAS) del Parlamento Europeo y del Consejo, de 23 de julio de 2014, relativo a la identificación electrónica y los servicios de confianza para las transacciones electrónicas en el mercado interior y por el que se deroga la Directiva 1999/93/CE	</li>
					<li>Ley 10/2021, de 9 de julio, de trabajo a distancia	</li>
					<li>Ley 1/2019, de 20 de febrero, de Secretos Empresariales.	</li>
					<li>Estatuto de los Trabajadores.	</li>
					<li>Convenio colectivo estatal de empresas de consultoría, tecnologías de la información y estudios de mercado y de la opinión pública	</li>
					<li>Ley 31/1995, de 8 de noviembre, de prevención de Riesgos Laborales.	</li>
				</ul>
				<p>
				<br />
					<a href="/resources/transp/normativa.txt" target="_blank">Normativa - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>2. Información relativa a las funciones y competencias, al objeto social o al fin fundacional de la entidad</h2>
				<p>
					La sociedad tiene por objeto el desarrollo de las siguientes actividades:
				<ul>
					<li>Actividades de programación informática.</li>
					<li>Actividades de consultoría informática</li>
				</ul>
				<br />
					<a href="/resources/transp/objeto_social.txt" target="_blank">Objeto social - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>3. Organigrama de la entidad</h2>
				<p>
					<img
							src='/images/organigrama_kuflow.png'
							width='100%'
							style={{ margin: '0 auto' }}
							alt='Organigrama'
					/>
					<br /><br />
					<a href="/resources/transp/organigrama.txt" target="_blank">Organigrama - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>4. Identificación, perfil, méritos académicos y trayectoria profesional (de los administradores y directivos).</h2>
				<p>
					<br />
					<strong>Carlos Javier Peña Dorta</strong>
					<ul style={{marginTop: "0px"}}>
						<li>Socio fundador</li>
						<li>Ingeniero en Informática por la Universidad de La Laguna</li>
						<li>Dirección General en KuFlow: 2021 - actualidad</li>
						<li>Dirección General en Arte Consultores: Julio 2005 - actualidad</li>
					</ul>
				</p>
				<p>
				<strong>Antonio Manuel López González</strong>
				<ul style={{marginTop: "0px"}}>
					<li>Socio fundador</li>
					<li>Ingeniero en Informática por la Universidad de La Laguna</li>
					<li>Dirección General en KuFlow: 2021 - actualidad</li>
					<li>Dirección General en Arte Consultores: Julio 2005 - actualidad</li>
				</ul>
				<br />
					<a href="/resources/transp/administradores.txt" target="_blank">Administradores - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>5. Retribución percibida anualmente por los administradores</h2>
				<p>
					En 2022 los administradores no han percibido retribuciones.
					<br />
					En 2023 los administradores no han percibido retribuciones.
					<br /><br />
					<a href="/resources/transp/retribuciones.csv">Retribuciones - Formato CSV</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>6. Descripción del uso del Canal Interno de Información de la Ley 2/2023, y principios esenciales del procedimiento de gestión</h2>
				<p>
					No aplicable. Esta entidad no es referida en el artículo 10.1 de la Ley 2/2023
					<br /><br />
					<a href="/resources/transp/canalinterno.txt" target="_blank">Canal Interno de Información - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>7. Información presupuestaria y contable. Cuentas anuales</h2>
				<p>
					<strong>Año 2022</strong>
					<table width="100%">
  					<tr>
    					<th width="75%">Activo</th>
    					<th width="25%">€</th>
  					</tr>
					<tr>
						<td>A) ACTIVO NO CORRIENTE	</td>
						<td align="right">247,41 €</td>
					</tr>
					<tr>
						<td>&nbsp;II. Inmovilizado material	</td>
						<td align="right">247,41 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;217    EQUIPOS PARA PROCESOS DE INFORMACIÓN	</td>
						<td align="right">281,67 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;281    AMORTIZACIÓN ACUMULADA DEL INMOVILIZADO MATERIAL</td>
						<td align="right">-34,26 €</td>
					</tr>
					<tr>
						<td>B) ACTIVO CORRIENTE	</td>
						<td align="right">137.430,95 €</td>
					</tr>
					<tr>
						<td>&nbsp;II. Deudores comerciales y otras cuentas a cobrar	</td>
						<td align="right">69.421,60 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;1. Clientes ventas y prestación de servicios</td>
						<td align="right">69.421,60 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;b) Cltes.ventas y prestación servicios CP	</td>
						<td align="right">69.421,60 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;430    CLIENTES	</td>
						<td align="right">69.421,60 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;3. Otros deudores	</td>
						<td align="right">	0 €</td>
					</tr>
					<tr>
						<td>&nbsp;VI. Efectivo y otros activos líquidos equivalentes</td>
						<td align="right">68.009,35 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;572    BANCOS E INSTITUCIONES DE CRÉDITO</td>
						<td align="right">68.009,35 €</td>
					</tr>
					<tr>
						<td><strong>TOTAL ACTIVO</strong></td>
						<td align="right">137.678,36 €</td>
					</tr>
					</table>
					<br />
					<br />

					<table width="100%">
  					<tr>
    					<th width="75%">Pasivo</th>
    					<th width="25%">€</th>
  					</tr>
					<tr>
						<td>A) PATRIMONIO NETO	</td>
						<td align="right">-123.119 €</td>
					</tr>
					<tr>
						<td>A-1) Fondos propios	</td>
						<td align="right">-123.119 €</td>
					</tr>
					<tr>
						<td>&nbsp;I. Capital</td>
						<td align="right">20.000 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;1. Capital escriturado</td>
						<td align="right">20.000 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;100    CAPITAL SOCIAL</td>
						<td align="right">20.000 €</td>
					</tr>
					<tr>
						<td>&nbsp;V. Resultados de ejercicios anteriores</td>
						<td align="right">	-85.769 €</td>
					</tr>
					<tr>
						<td>&nbsp;&nbsp;121    RESULTADOS NEGATIVOS DE EJERCICIOS ANTERIORES	</td>
						<td align="right">-85.769 €</td>
					</tr>
					<tr>
						<td>&nbsp;VII. Resultado del ejercicio	</td>
						<td align="right">-57.350 €</td></tr>
					<tr>
						<td>B) PASIVO NO CORRIENTE	</td>
						<td align="right">193.651 €</td></tr>
					<tr>
						<td>&nbsp;II. Deudas a largo plazo	</td>
						<td align="right">193.651 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;3. Otras deudas a largo plazo	</td>
						<td align="right">193.651 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;171    DEUDAS A LARGO PLAZO	</td>
						<td align="right">193.651 €</td></tr>
					<tr>
						<td>C) PASIVO CORRIENTE	</td>
						<td align="right">67.147 €</td></tr>
					<tr>
						<td>&nbsp;II. Deudas a corto plazo	</td>
						<td align="right">34.168 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;1. Deudas con entidades de credito	</td>
						<td align="right">359 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;520    DEUDAS A CORTO PLAZO CON ENTIDADES DE CRÉDITO</td>
						<td align="right">359 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;3. Otras deudas a corto plazo	</td>
						<td align="right">33.810 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;521    DEUDAS A CORTO PLAZO	</td>
						<td align="right">33.810 €</td></tr>
					<tr>
						<td>&nbsp;IV. Acreedores comerc. y otras cuentas a pagar	</td>
						<td align="right">32.979 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;2. Otros acreedores	</td>
						<td align="right">32.979 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;410    ACREEDORES POR PRESTACIONES DE SERVICIO</td>
						<td align="right">1.194 €	</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;465    REMUNERACIONES PENDIENTES DE PAGO</td>
						<td align="right">13.596 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;475    HACIENDA PÚBLICA, ACREEDORA POR CONCEPTOS FISCALES</td>
						<td align="right">12.263 €</td></tr>
					<tr>
						<td>&nbsp;&nbsp;&nbsp;476    ORGANISMOS DE LA SEGURIDAD SOCIAL</td>
						<td align="right">5.925 €</td></tr>
					<tr>
						<td><strong>TOTAL  PATRIMONIO NETO Y PASIVO</strong></td>
						<td align="right">137.678 €</td></tr>
					</table>

					<br />
					<br />

					<table width="100%">
  					<tr>
    					<th width="75%">Cuenta de pérdidas y ganancias</th>
    					<th width="25%">€</th>
  					</tr>
					<tr>
						<td>1. Importe neto de la cifra de negocios</td>
						<td align="right">74.880 €</td>
					</tr>
					<tr>
						<td>&nbsp;705    PRESTACIONES DE SERVICIOS</td>
						<td align="right">74.880 €</td>
					</tr>
					<tr>
						<td>5. Otros ingresos de explotación</td>
						<td align="right">100.250 €</td>
					</tr>
					<tr>
						<td>&nbsp;740    SUBVENCIONES, DONACIONES Y LEGADOS</td>
						<td align="right">100.250 €</td>
					</tr>
					<tr>
						<td>6. Gastos de personal</td>
						<td align="right">-208.897 €</td></tr>
					<tr>
						<td>&nbsp;640    SUELDOS Y SALARIOS</td>
						<td align="right">-163.635 €</td></tr>
					<tr>
						<td>&nbsp;642    SEGURIDAD SOCIAL A CARGO DE LA EMPRESA</td>
						<td align="right">-45.262 €</td>
					</tr>
					<tr>
						<td>7. Otros gastos de explotación</td>
						<td align="right">-15.967 €</td>
					</tr>
					<tr>
						<td>&nbsp;623    SERVICIOS DE PROFESIONALES INDEPENDIENTES</td>
						<td align="right">-3.376 €</td>
					</tr>
					<tr>
						<td>&nbsp;624    TRANSPORTES</td>
						<td align="right">-478 €	</td>
					</tr>
					<tr>
						<td>&nbsp;625    PRIMAS DE SEGUROS</td>
						<td align="right">-479 €	</td>
					</tr>
					<tr>
						<td>&nbsp;626    SERVICIOS BANCARIOS Y SIMILARES</td>
						<td align="right">-87 €	</td>
					</tr>
					<tr>
						<td>&nbsp;629    OTROS SERVICIOS</td>
						<td align="right">-10.001 €	</td>
					</tr>
					<tr>
						<td>&nbsp;631    OTROS TRIBUTOS</td>
						<td align="right">-1.545 €</td>
					</tr>
					<tr>
						<td>8. Amortización de inmovilizado	</td>
						<td align="right">-34 €</td>
					</tr>
					<tr>
						<td>&nbsp;681    AMORTIZACIÓN DEL INMOVILIZADO</td>
						<td align="right">-34 €</td>
					</tr>
					<tr>
						<td><strong>A) RESULTADO DE EXPLOTACIÓN</strong></td>
						<td align="right">-49.768 €</td>
					</tr>
					<tr>
						<td>14. Gastos financieros</td>
						<td align="right">-7.582 €</td>
					</tr>
					<tr>
						<td>&nbsp;662    INTERESES DE DEUDAS</td>
						<td align="right">-7.582 €</td>
					</tr>
					<tr>
						<td><strong>B) RESULTADO FINANCIERO</strong></td>
						<td align="right">-7.582 €</td>
					</tr>
					<tr>
						<td><strong>C) RESULTADO ANTES DE IMPUESTOS</strong></td>
						<td align="right">-57.350 €	</td>
					</tr>
					<tr>
						<td><strong>D) RESULTADO DEL EJERCICIO</strong></td>
						<td align="right">-57.350 €</td>
					</tr>
					</table>
					<br />
					<a href="/resources/transp/e2022_activo.csv">Ejercicio 2022 - Activo - Formato CSV</a><br />
					<a href="/resources/transp/e2022_pasivo.csv">Ejercicio 2022 - Pasivo - Formato CSV</a><br />
					<a href="/resources/transp/e2022_pyg.csv">Ejercicio 2022 - Pérdidas y ganancias - Formato CSV</a>
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>8. Información presupuestaria y contable. Informes de auditoría de cuentas y de fiscalización por los órganos de control externo</h2>
				<p>
					La entidad no está obligada a auditar sus cuentas, a tenor de lo recogido en el artículo 257 del Real Decreto Legislativo 1/2010 de 2 de julio (Ley de Sociedades de Capital), dado que no cumple con ninguno de los siguientes requisitos:
					<ul style={{marginTop: "0px"}}>
						<li>Que el importe neto de la cifra de negocio supere los 5.700.000 euros</li>
						<li>Que el total de los activos supere los 2.850.000 euros</li>
						<li>Que el número medio de trabajadores durante el ejercicio supere los 50</li>
					</ul>
					<br />
					<a href="/resources/transp/auditoria.txt" target="_blank">Informes de auditorías - Formato TXT</a><br />
					<br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>9. Contratos formalizados con entidades públicas. Detalle</h2>
				<p>
				<strong>Año 2022</strong>
				<table width="100%">
  					<tr>
    					<th width="15%">Entidad</th>
    					<th width="40%">Objeto</th>
    					<th width="15%">Duración</th>
						<th width="15%">Importe de adjudicación</th>
						<th width="35%">Procedimiento</th>
  					</tr>
  					<tr>
    					<td>Autoridad Portuaria de Tenerife</td>
    					<td>Servicio de desarrollo de un sistema de información para la tramitación de los procedimientos de la Policía Portuaria en la Autoridad Portuaria de Santa Cruz de Tenerife</td>
    					<td align='center'>3 meses</td>
						<td align='right'>14.900 €</td>
						<td>Contrato menor</td>
  					</tr>
				</table>
				</p>
				<p>
				<strong>Año 2023</strong>
				<table width="100%">
  					<tr>
					  <th width="15%">Entidad</th>
    					<th width="40%">Objeto</th>
    					<th width="15%">Duración</th>
						<th width="15%">Importe de adjudicación</th>
						<th width="35%">Procedimiento</th>
  					</tr>
  					<tr>
    					<td>Autoridad Portuaria de Tenerife</td>
    					<td>Servicio de desarrollo de una automatización para la certificación mensual de proyectos</td>
    					<td align='center'>3 meses</td>
						<td align='right'>3.400 €</td>
						<td>Contrato menor</td>
  					</tr>
  					<tr>
    					<td>Ayuntamiento de La Laguna</td>
    					<td>Adaptación de bonos de comercio a las particulares de La Noche en Blanco 2023 (adaptación, modificación de la plataforma, adaptación y reconfiguración de la web y otros para el día 25 de noviembre y madrugada del día 26
de noviembre de 2023)</td>
    					<td align='center'>1 mes</td>
						<td align='right'>14.900 €</td>
						<td>Contrato menor</td>
  					</tr>
				</table>
				<br />
				<a href="/resources/transp/contratos.csv">Contratos - Formato CSV</a><br />
				<br />
				</p>
				<p>&nbsp;</p>
				<h2>10. Contratos formalizados con entidades públicas. Datos estadísticos</h2>
				<p>
				<table width="100%">
  					<tr>
    					<th>Año</th>
    					<th>Tipo de contrato</th>
    					<th>Porcentaje</th>
  					</tr>
  					<tr>
    					<td align='center'>2022</td>
    					<td>Contratos menores</td>
    					<td align='center'>100%</td>
  					</tr>
  					<tr>
    					<td align='center'>2023</td>
    					<td>Contratos menores</td>
    					<td align='center'>100%</td>
  					</tr>
				</table>
				<br />
				<a href="/resources/transp/contratos_porcentajes.csv">Contratos (datos estadísticos) - Formato CSV</a><br />
				<br />
				</p>
				<p>&nbsp;</p>
				<h2>11. Contratos formalizados con entidades públicas. Modificaciones</h2>
				<p>
					No ha habido modificaciones de los contratos formalizados.
					<br /><br />
					<a href="/resources/transp/contratos_modificaciones.txt" target="_blank">Contratos (modificaciones) - Formato TXT</a><br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>12. Relación de contratos resueltos con entidades públicas</h2>
				<p>
					No ha habido decisiones de desistimiento o renuncia de contratos con Administraciones Públicas.
					<br /><br />
					<a href="/resources/transp/contratos_resoluciones.txt" target="_blank">Contratos (resoluciones) - Formato TXT</a><br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>13. Información trimestral de contratos menores con entidades públicas</h2>
				<p>
				<strong>Año 2022</strong>
				<table width="100%">
  					<tr>
    					<th width="15%">Entidad</th>
    					<th width="40%">Objeto</th>
    					<th width="15%">Duración</th>
						<th width="15%">Importe de adjudicación</th>
						<th width="35%">Procedimiento</th>
  					</tr>
  					<tr>
    					<td>Autoridad Portuaria de Tenerife</td>
    					<td>Servicio de desarrollo de un sistema de información para la tramitación de los procedimientos de la Policía Portuaria en la Autoridad Portuaria de Santa Cruz de Tenerife</td>
    					<td align='center'>3 meses</td>
						<td align='right'>14.900 €</td>
						<td>Contrato menor</td>
  					</tr>
				</table>
				</p>
				<p>
				<strong>Año 2023</strong>
				<table width="100%">
  					<tr>
					  <th width="15%">Entidad</th>
    					<th width="40%">Objeto</th>
    					<th width="15%">Duración</th>
						<th width="15%">Importe de adjudicación</th>
						<th width="35%">Procedimiento</th>
  					</tr>
  					<tr>
    					<td>Autoridad Portuaria de Tenerife</td>
    					<td>Servicio de desarrollo de una automatización para la certificación mensual de proyectos</td>
    					<td align='center'>3 meses</td>
						<td align='right'>3.400 €</td>
						<td>Contrato menor</td>
  					</tr>
  					<tr>
    					<td>Ayuntamiento de La Laguna</td>
    					<td>Adaptación de bonos de comercio a las particulares de La Noche en Blanco 2023 (adaptación, modificación de la plataforma, adaptación y reconfiguración de la web y otros para el día 25 de noviembre y madrugada del día 26
de noviembre de 2023)</td>
    					<td align='center'>1 mes</td>
						<td align='right'>14.900 €</td>
						<td>Contrato menor</td>
  					</tr>
				</table>
				<br />
				<a href="/resources/transp/contratos.csv">Contratos - Formato CSV</a><br />
				<br />
				</p>
				<p>&nbsp;</p>
				<h2>14. Convenios con entidades públicas</h2>
				<p>
					No se han formalizados convenios
					<br /><br />
					<a href="/resources/transp/convenios.txt" target="_blank">Convenios - Formato TXT</a><br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>15. Convenios con entidades públicas. Modificaciones realizadas durante la vigencia: objeto y fecha</h2>
				<p>
					No se han formalizados convenios
					<br /><br />
					<a href="/resources/transp/convenios_modificaciones.txt" target="_blank">Convenios (modificaciones) - Formato TXT</a><br />
					<br />
				</p>
				<p>&nbsp;</p>
				<h2>16. Relación de ayudas y subvenciones concedidas</h2>
				<p>
				<table width="100%">
  					<tr>
					  	<th width="10%">Año</th>
					  	<th width="30%">Subvención</th>
    					<th width="20%">Administración concedente</th>
    					<th width="20%">Finalidad</th>
						<th width="20%">Importe</th>
  					</tr>
  					<tr>
						<td align='center'>2022</td>
    					<td>Subvenciones a empresas de alta tecnología e intensivas en conocimiento en áreas prioritarias de la RIS3 concedidas por el programa operativo FEDER Canarias 2014-2020</td>
    					<td>Agencia Canaria de Investigación, Innovación y Sociedad de la Información</td>
    					<td>Desarrollo de una plataforma de automatización de procesos</td>
						<td align='right'>120.888,64 €</td>
  					</tr>
  					<tr>
						<td align='center'>2023</td>
    					<td>Subvenciones a empresas de alta tecnología e intensivas en conocimiento en áreas prioritarias de la RIS3 concedidas por el programa operativo FEDER Canarias 2014-2020</td>
    					<td>Agencia Canaria de Investigación, Innovación y Sociedad de la Información</td>
    					<td>Desarrollo de una plataforma de automatización de procesos</td>
						<td align='right'>27.899,90 €</td>
  					</tr>
					<tr>
						<td align='center'>2023</td>
    					<td>Programa Investigo de contratación de personas jóvenes demandantes de empleo en la realización de iniciativas de investigación e innovación, en el marco del Plan de Recuperación, Transformación y Resiliencia - Next Generation EU</td>
    					<td>Servicio Canario de Empleo</td>
    					<td>Desarrollo de un ecosistema de conectores con RPAs</td>
						<td align='right'>66.217,84 €</td>
  					</tr>
				</table>
				<br />
				<a href="/resources/transp/subvenciones.csv">Subvenciones - Formato CSV</a><br />
				<br />
				</p>
				<p>&nbsp;</p>
				<h2>Contenido adicional</h2>
				<p>
					<a href="https://www3.gobiernodecanarias.org/juriscan/ficha.jsp?id=71248" target="_blank">Ley 12/2014, 26 diciembre, de transparencia y de acceso a la información pública</a>
					<br /><br />
					<a href="https://transparenciacanarias.org/" target="_blank">Web de Comisión de Transparencia</a>
					<br />
				</p>
			</div>
		</LayoutES>
	);
}
